
function getTestImageCanvas(width = 100, height = 100) {
  function getRandomColor() {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) { // NOSONAR
      // eslint-disable-next-line no-magic-numbers
      color += letters[Math.floor(Math.random() * 16)]; // NOSONAR
    }
    return color;
  }

  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext('2d');
  const tmpG = ctx.createLinearGradient(0, 0, width, height);
  tmpG.addColorStop(0, '#33f');
  // eslint-disable-next-line no-magic-numbers
  tmpG.addColorStop(0.5, getRandomColor()); // NOSONAR
  tmpG.addColorStop(1, '#333');
  ctx.fillStyle = tmpG;
  ctx.fillRect(0, 0, width, height);
  return {
    canvas, ctx
  };
}

export function getTestImageSrc(width = 100, height = 100, type = 'image/png'): string {
  const obj = getTestImageCanvas(width, height);
  return obj.canvas.toDataURL(type);
}

export function getTestImageBlob(width = 100, height = 100, type = 'image/png'): Promise<Blob> {
  const obj = getTestImageCanvas(width, height);
  return new Promise((resolve, reject) => {
    obj.canvas.toBlob(blob => {
      resolve(blob);
    }, type);
  });
}

/**
 * quickly shows an image (for testing purposes and poc: thinking about using a <dialog> for the UtilModalService)
 */
export function showTestImageInDialog(img: HTMLImageElement): HTMLDialogElement {

  // style

  let styleEl = document.head.querySelector('#show-test-image-in-dialog-style') as HTMLStyleElement;

  if (!styleEl) {
    styleEl = document.createElement('style');
    styleEl.setAttribute('id', 'show-test-image-in-dialog-style');
    const styleRuleTextEl = document.createTextNode(`
      /* reset rule */
      #show-test-image-in-dialog {
        padding: unset;
        border: unset;
      }

      #show-test-image-in-dialog {
        position: absolute;
        inset: 0;
      }
    `);
    styleEl.appendChild(styleRuleTextEl);
    document.head.appendChild(styleEl);
  }

  // dialog

  const dialogEl = document.createElement('dialog');
  dialogEl.setAttribute('id', 'show-test-image-in-dialog');

  document.body.appendChild(dialogEl);
  dialogEl.appendChild(img);

  dialogEl.showModal(); // not dialogEl.show() or backdrop wont be displayed

  let onModalClick: (me: MouseEvent) => void;

  onModalClick = me => {
    const rect = dialogEl.getBoundingClientRect();
    const isInDialog = (rect.top <= me.clientY && me.clientY <= rect.top + rect.height && rect.left <= me.clientX && me.clientX <= rect.left + rect.width);

    if (!isInDialog) {
      dialogEl.close();
    }
  };

  dialogEl.addEventListener('close', () => {
    dialogEl.removeEventListener('click', onModalClick);
    img.parentElement.removeChild(img);
    dialogEl.parentElement.removeChild(dialogEl);
  });

  dialogEl.addEventListener('click', onModalClick);

  return dialogEl;

}
