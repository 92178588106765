import { NgModule } from '@angular/core';
import { I18nConfig, provideDefaultConfig } from '@spartacus/core';


const i18nConfig: I18nConfig = {
  i18n: {
    resources: {
      en: {
        validation: {
          validation: {
            noTextSet: 'no error message is set',
            input: {
              appInputRequiredValidator: 'Input field must not be empty',
              appInputForbiddenValidator: 'Input field must be empty',
              appInputLateDateValidator: 'The selected date is in the past',
              appInputMaxLengthValidator: 'Input must not be longer than {{n}} character',
              appInputMinLengthValidator: 'Input must be at least {{n}} characters long',
              appInputSameAsValidator: 'Input must be the same as "{{targetLabel}}"',
              appInputRegExpCharacterValidator: 'Input does not match the given pattern.{{unmatchedPattern}}',
              appInputEmailValidator: 'Input is not a valid email address',
              appInputWithinMinMaxValidator: 'Input must be within {{min}} and {{max}}',
              appInputWithinMinMaxValidator_onlyMin: 'Input must be greater or equal to {{min}}',
              appInputWithinMinMaxValidator_onlyMax: 'Input must be smaller or equal to {{max}}',
              appInputWithinMinMaxValidator_minMaxSameNum: 'Input must be {{min}}'
            },
            textarea: {
              appTextareaRequiredValidator: 'Text field must not be empty',
            },
            checkbox: {
              appCheckedValidator: 'Must be checked'
            }
          }
        }
      },
      de: {
        validation: {
          validation: {
            noTextSet: 'es wurde noch keine Fehlermeldung gesetzt',
            input: {
              appInputRequiredValidator: 'Das Eingabefeld darf nicht leer sein',
              appInputForbiddenValidator: 'Das Eingabefeld muss leer sein',
              appInputLateDateValidator: 'Das ausgewählte Datum liegt in der Vergangenheit',
              appInputMaxLengthValidator: 'Die Eingabe darf nicht länger als {{n}} Zeichen sein',
              appInputMinLengthValidator: 'Die Eingabe muss mindestens {{n}} Zeichen lang sein',
              appInputSameAsValidator: 'Die Eingabe muss mit "{{targetLabel}}" übereinstimmen',
              appInputRegExpCharacterValidator: 'Die Eingabe stimmt nicht mit den vorgegebenen Mustern überein.{{unmatchedPattern}}',
              appInputEmailValidator: 'Die Eingabe ist keine gültige Email-Adresse',
              appInputWithinMinMaxValidator: 'Die Eingabe muss zwischen {{min}} und {{max}} sein',
              appInputWithinMinMaxValidator_onlyMin: 'Die Eingabe muss größer oder gleich {{min}} sein',
              appInputWithinMinMaxValidator_onlyMax: 'Die Eingabe muss kleiner oder gleich {{max}} sein',
              appInputWithinMinMaxValidator_minMaxSameNum: 'Die Eingabe muss {{min}} sein'
            },
            textarea: {
              appTextareaRequiredValidator: 'Das Textfeld darf nicht leer sein',
            },
            checkbox: {
              appCheckedValidator: 'Muss geprüft werden'
            }
          }
        }
      },
      fr: {
        validation: {
          validation: {
            noTextSet: 'aucun message d\'erreur n\'a encore été défini',
            input: {
              appInputRequiredValidator: 'Le champ de saisie ne doit pas être vide',
              appInputForbiddenValidator: 'Le champ de saisie doit être vide',
              appInputLateDateValidator: 'La date sélectionnée se situe dans le passé',
              appInputMaxLengthValidator: 'La saisie ne doit pas dépasser {{n}} caractères',
              appInputMinLengthValidator: 'La saisie doit comporter au moins {{n}} caractères',
              appInputSameAsValidator: 'L\'entrée doit être la même que "{{targetLabel}}"',
              appInputRegExpCharacterValidator: 'L\'entrée ne correspond pas au modèle donné.{{unmatchedPattern}}',
              appInputEmailValidator: 'L\'entrée n\'est pas une adresse e-mail valide',
              appInputWithinMinMaxValidator: 'La saisie doit être comprise entre {{min}} et {{max}}',
              appInputWithinMinMaxValidator_onlyMin: 'L\'entrée doit être supérieure ou égale à {{min}}',
              appInputWithinMinMaxValidator_onlyMax: 'L\'entrée doit être inférieure ou égale à {{max}}',
              appInputWithinMinMaxValidator_minMaxSameNum: 'L\'entrée doit être {{min}}'
            },
            textarea: {
              appTextareaRequiredValidator: 'Le champ de texte ne peut pas être vide',
            },
            checkbox: {
              appCheckedValidator: 'Doit être vérifié'
            }
          }
        }
      },
      nl: {
        validation: {
          validation: {
            noTextSet: 'er is nog geen foutbericht ingesteld',
            input: {
              appInputRequiredValidator: 'Het invoerveld mag niet leeg zijn',
              appInputForbiddenValidator: 'Het invoerveld moet leeg zijn',
              appInputLateDateValidator: 'De geselecteerde datum ligt in het verleden',
              appInputMaxLengthValidator: 'De invoer mag niet langer zijn dan {{n}} tekens',
              appInputMinLengthValidator: 'De invoer moet minimaal {{n}} tekens lang zijn',
              appInputSameAsValidator: 'De invoer moet hetzelfde zijn als "{{targetLabel}}"',
              appInputRegExpCharacterValidator: 'Invoer komt niet overeen met het opgegeven patroon.{{unmatchedPattern}}',
              appInputEmailValidator: 'Invoer is geen geldig e-mailadres',
              appInputWithinMinMaxValidator: 'De invoer moet tussen {{min}} en {{max}} liggen',
              appInputWithinMinMaxValidator_onlyMin: 'Invoer moet groter zijn dan of gelijk zijn aan {{min}}',
              appInputWithinMinMaxValidator_onlyMax: 'Invoer moet kleiner zijn dan of gelijk zijn aan {{max}}',
              appInputWithinMinMaxValidator_minMaxSameNum: 'Invoer moet {{min}} zijn'
            },
            textarea: {
              appTextareaRequiredValidator: 'Het tekstveld mag niet leeg zijn',
            },
            checkbox: {
              appCheckedValidator: 'Moet worden gecontroleerd'
            }
          }
        }
      },
    },
    chunks: {
      validation: ['validation']
    },
    fallbackLang: 'en'
  }
};


@NgModule({
  providers: [
    provideDefaultConfig(i18nConfig)
  ]
})
export class BaseFormValidatorsTranslationsModule { }

