/* eslint-disable no-magic-numbers */
import { HttpClient, } from '@angular/common/http';
import { Injectable, OnDestroy, inject } from '@angular/core';
import { AuthService, ConverterService, Country, EventService, Language, PRODUCT_NORMALIZER, User } from '@spartacus/core';
import { UserAccountChangedEvent, UserAccountFacade } from '@spartacus/user/account/root';
import { AsyncDataCache } from '@util/classes/async-data-cache.class';
import { KurzTabBarStructure } from '@util/components/tab-bar/tab-bar.types';
import { getUrlOfEndpoint, getUrlOfSingleMedia } from '@util/functions/strings';
import { UtilModalService } from '@util/services/util-modal.service';
import { Observable, Subject, Subscription, combineLatest, forkJoin, from, of } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, skip, switchMap, take, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { languageFallbackMap } from '../custom-configuration-modules/custom-translations.module';
import { getTestImageSrc } from '../functions/image.functions';
import { KurzHomepageIndustriesData } from '../kurz-components/kurz-hompage/kurz-hompage-industries/industries.type';
import { KurzLastVisitedProductsData } from '../kurz-components/kurz-hompage/kurz-last-visited-products/kurz-last-visited-products.types';
import { kurzMyDetailsTabsMockJSON} from '../kurz-components/kurz-my-account/kurz-my-details/kurz-my-details-json.interface';
import { KurzMyProfile } from '../kurz-components/kurz-my-account/kurz-my-profile/kurz-my-profile.component';
import { KurzAcceptLegalDocumentModalData } from '../kurz-components/shared/kurz-accept-legal-document-modal/kurz-accept-legal-document-modal.component';
import { KurzAddress, KurzAddressList, KurzConditionList } from '../kurz-components/shared/types/kurz-adress.interface';
import { KurzBaseStoreCompanyProperties } from '../kurz-components/shared/types/kurz-base-store-company-properties.interface';
import { KurzProduct } from '../kurz-components/shared/types/kurz-product.interface';
import { KurzAccountManager, KurzUser } from '../kurz-components/shared/types/kurz-user.interface';
import { KurzCartService } from './kurz-cart.service';
import { KurzLanguageService } from './kurz-language.service';
import { KurzAreaUnitCode } from './kurz-unit.service';
import { OccApiCacheInterceptor } from '../http-interceptors/occ-api-cache.interceptor';
import { getMillisecondsOfUnits } from '@util/functions/date';


export type KurzPreSelectedRedirectListElement = {
  id: string;
  url: string;
  preselected?: boolean;
};

/**
 * creating Occ api cache for my profile
 */
OccApiCacheInterceptor.registerUrlPartForCaching(
  'my-details/my-profile',
  {
    validFor: getMillisecondsOfUnits('2h'),
    ignoreSearchParamList: ['curr']
  }
);

@Injectable({providedIn: 'root'})
export class KurzMyAccountService implements OnDestroy {

  private readonly kurzLanguageService = inject(KurzLanguageService);
  private readonly httpClient = inject(HttpClient);
  private readonly kurzCartService = inject(KurzCartService);
  private readonly eventService = inject(EventService);
  private readonly authService = inject(AuthService);
  private readonly utilModalService = inject(UtilModalService);
  private readonly userAccountFacade = inject(UserAccountFacade);
  private readonly converterService = inject(ConverterService);


  private kurzUserIndustriesAsyncDataSync = new AsyncDataCache(
    '_kurzUserIndustries',
    {
      dataSerializable: false,
      // eslint-disable-next-line no-magic-numbers
      maxAge: (1000 * 60 * 60 * 4),
      sources: [
        {
          key: 'userIndustries',
          object: {
            source: () => (this.requestUserIndustries().pipe(filter(data => !!data))),
            refreshWhen: combineLatest([
              this.authService.isUserLoggedIn(),
              this.kurzLanguageService.getActive()
            ]).pipe(skip(1))
          }
        }
      ]
    }
  );

  private kurzLastVisitedProductsDataSync = new AsyncDataCache(
    '_kurzLastVisitedProducts',
    {
      dataSerializable: false,
      maxAge: 1000,
      sources: [
        {
          key: 'kurzLastVisitedProducts',
          object: {
            source: () => this.requestLastVisitedProductsData(),
            refreshWhen: this.authService.isUserLoggedIn().pipe(skip(1))
          }
        }
      ]
    }
  );

  private kurzCountriesAsyncDataCache = new AsyncDataCache(
    '_kurzCountriesAsyncDataCaches',
    {
      dataSerializable: false,
      maxAge: 1000 * 60 * 60 * 8, // NOSONAR
      sources: [
        {
          key: 'kurzCountriesAsyncDataCache',
          object: {
            source: () => {
              const url = getUrlOfEndpoint('/countries');
              return this.httpClient.get<{countries: Country[];}>(url);
            },
            refreshWhen: this.kurzLanguageService.getActive().pipe(skip(1))
          }
        }
      ]
    }
  );

  private kurzAllLanguagesAsyncDataCache = new AsyncDataCache(
    '_kurzAllLanguagesAsyncDataCaches',
    {
      dataSerializable: false,
      maxAge: 1000 * 60 * 60 * 8, // NOSONAR
      sources: [
        {
          key: 'kurzAllLanguagesAsyncDataCache',
          object: {
            source: () => {
              // TODO get an endpoint, from which the FE receives all available languages in the backend
              return of(<Language[]>[
                {isocode: 'en', name: 'Englisch'},
                {isocode: 'de', name: 'Deutsch'},
                {isocode: 'fr', name: 'Französisch'},
                {isocode: 'nl', name: 'Niederländisch'}
              ]);
            },
            refreshWhen: this.kurzLanguageService.getActive().pipe(skip(1))
          }
        }
      ]
    }
  );

  private kurzRedirectListAsyncDataCache = AsyncDataCache.create({
    source: () => (this.requestRedirectListWithPreselectedUserLocation()),
    refreshWhen: this.authService.isUserLoggedIn().pipe(skip(1))
  });

  private checkAgreementSub: Subscription;

  constructor() {

    // note: does not only trigger on successful oauth but refresh as well
    this.checkAgreementSub = this.authService.isUserLoggedIn().pipe(distinctUntilChanged()).subscribe(isLoggedIn => {
      if (isLoggedIn) {
        this.checkAcceptanceOfTermsOfUse();
      }
    });

  }

  ngOnDestroy(): void {
    this.checkAgreementSub?.unsubscribe();
    this.kurzUserIndustriesAsyncDataSync.complete();
  }

  /**
   * returns an observable of requested data and delivers next, everytime the user switches the language.
   * The Observable requests only then new data if cached data does not exist or if it is too old.
   * Otherwise the cached data will be returned.
   * @param cacheTimeout - Max age of the cached data (ms)
   * @param request - Function, which requests the data: e.g. HTTP-Requests
   */
  private getCachableLanguageSusceptibleObservable<T extends any>(cacheTimeout: number, request: () => Observable<T>) {
    let lastRequestTimestamp: number;
    let lastRequestLanguage: string;
    let cache: T;
    let currentLanguage: string;

    const observable = new Observable<T>(observer => {

      const now = new Date().getTime();
      lastRequestTimestamp = lastRequestTimestamp || new Date().getTime();
      const dif = (now - lastRequestTimestamp);
      if (!cache || lastRequestLanguage !== currentLanguage || dif > cacheTimeout) {
        // YES timeout -> new request in needed
        lastRequestTimestamp = new Date().getTime();
        lastRequestLanguage = currentLanguage;
        request().pipe(take(1)).subscribe(res => {
          cache = res;
          observer.next(res);
        });
      } else {
        // NO timeout -> use cache
        observer.next(cache);
      }
    });

    const resObs = this.kurzLanguageService.getActive()
      .pipe(
        tap(lang => currentLanguage = lang),
        switchMap(_ => observable)
      );
    return resObs;
  }

  getMyAccountTabStructure(): Observable<KurzTabBarStructure> {

    const url = getUrlOfEndpoint('/my-details/main-tabs');
    const cacheTimeout = 1500;

    return this.getCachableLanguageSusceptibleObservable(cacheTimeout, () => this.httpClient.get<KurzTabBarStructure>(url))
      .pipe(
        tap(res => {
          const settingsRes = res?.mainTabs?.find(tab => tab.queryParamIdentifier.includes('sett'));
          if (!settingsRes) {
            const settingsMock = kurzMyDetailsTabsMockJSON.mainTabs.find(tab => tab.queryParamIdentifier.includes('sett'));
            if (settingsMock) {
              res.mainTabs.push(settingsMock);
            }
          }
        })
      );
  }

  /**
   * returns an observable of the current profile and triggers next (new server request)
   * everytime the user switches the language
   * with kurz specific data
   */
  getProfile(): Observable<KurzMyProfile> {
    const url = getUrlOfEndpoint('/my-details/my-profile');
    return this.authService.isUserLoggedIn().pipe(
      switchMap(isUserLoggedIn => {
        if (isUserLoggedIn) {
          return this.httpClient.get<KurzMyProfile>(url);
        } else {
          console.log('user was not logged in when kurz profile was requested', {isUserLoggedIn});
          return of(<KurzMyProfile>{});
        }
      })
    );
  }

  getUser(): Observable<KurzUser> {
    return this.userAccountFacade.get() as unknown as Observable<KurzUser>;
  }

  /**
   * NOTE:
   * getting all delivery addresses and combining them with all payment addresses may be insufficient
   */
  getAddresses(): Observable<KurzAddress[]> {

    return combineLatest([
      this.kurzCartService.getDeliveryAddresses(),
      this.kurzCartService.getPaymentAddresses(),
    ]).pipe(
      map(([delAddresses, payAddresses]) => {

        const addressPool = [...delAddresses.addresses, ...payAddresses.addresses];
        const idSet = new Set<string>();

        return addressPool.filter(thisAddr => {
          const isNew = !idSet.has(thisAddr.id);
          idSet.add(thisAddr.id);
          return isNew;
        });

      })
    );


  }

  adaptToUserBackendLanguage() {

    forkJoin([
      this.kurzLanguageService.getActive().pipe(filter(_ => !!_), take(1)),
      this.getUser().pipe(filter(_ => !!_), take(1))
    ]).pipe(
      map(([activeISO, user]) => {
        return {
          frontend: activeISO,
          backend: user.language.isocode
        };
      })
    ).subscribe(obj => {

      // if there is need to adapt
      if (typeof obj.backend === 'string' && obj.frontend !== obj.backend) {
        this.kurzLanguageService.setActive(obj.backend);
      }

    });
  }

  setAreaUnitCode(areaUnitCode: KurzAreaUnitCode): Observable<string> {

    const params = {code: areaUnitCode};
    const url = getUrlOfEndpoint('/my-details/area-unit', params);
    return this.httpClient.put<string>(url, {}).pipe(
      tap(res => {
        this.eventService.dispatch({user: {}}, UserAccountChangedEvent);
      })
    );
  }

  getUserIndustries(): Observable<KurzHomepageIndustriesData> {
    return this.kurzUserIndustriesAsyncDataSync.getResponse();
  }

  private requestUserIndustries(): Observable<KurzHomepageIndustriesData> {

    const endpoint = '/my-details/industries';
    const url = getUrlOfEndpoint(endpoint);

    return this.httpClient.get<KurzHomepageIndustriesData>(url).pipe(
      catchError(err => {
        console.warn('error while requesting industries', err, 'return example data');

        const mockUrl = '/Baseprodukt---Klassifizierungen/c/baseProductClassification?query=:name-asc:allCategories:baseProductClassification';

        const mockData: KurzHomepageIndustriesData['industries'] = [
          {
            classificationId: 'DIGITAL_PRINTING_industry',
            name: 'Digitaldruck',
            link: mockUrl,
            image: {
              code: 'dp',
              mime: 'image/jpg',
              url: getTestImageSrc(64, 40) // NOSONAR
            },
          },
          {
            classificationId: 'GRAPHIC_industry',
            name: 'Grafische Industrie',
            link: mockUrl,
            image: {
              code: 'g',
              mime: 'image/jpg',
              url: getTestImageSrc(64, 40) // NOSONAR
            },
          },
          {
            classificationId: 'TV_AUTOMOTIVE_GENERAL_PLASTICS_industry',
            name: 'Kunststoff allgemein',
            link: mockUrl,
            image: {
              code: 'tv',
              mime: 'image/jpg',
              url: getTestImageSrc(64, 40) // NOSONAR
            },
          },
          {
            classificationId: 'PLASTIC_COSMETIC_industry',
            name: 'Kunststoff-Kosmetik-Industrie',
            link: mockUrl,
            image: {
              code: 'kk',
              mime: 'image/jpg',
              url: getTestImageSrc(64, 40) // NOSONAR
            },
          },
          {
            classificationId: 'TEXTILE_industry',
            name: 'Textilindustrie',
            link: mockUrl,
            image: {
              code: 'kk',
              mime: 'image/jpg',
              url: getTestImageSrc(64, 40) // NOSONAR
            },
          },
        ];

        return of({
          industries: mockData,
          url: mockUrl
        });

      }),
      map(data => {
        data.industries = data.industries.map(industry => {
          if (!industry.image?.url) {
            industry.image ||= {};
            industry.image.url = getTestImageSrc(64, 40); // NOSONAR
            console.warn('image was not found of this industry', industry);
          }
          if (industry.image.url && !industry.image.url.startsWith('http') && !industry.image.url.startsWith('data')) {
            industry.image.url = getUrlOfSingleMedia(industry.image.url);
          }
          return industry;
        });
        return data;
      }),
      tap(data => {
        if (!data.url) {
          data.url = '/Baseprodukt---Klassifizierungen/c/baseProductClassification?query=:name-asc:allCategories:baseProductClassification';
          console.warn(`Url to all products was not found in response of "${endpoint}" and a hardcoded url was added`);
        }
      })
    );

  }

  /**
   * @returns last visited products as a cold observable - which means, if you subscribe to the returned observable, you will get freshly produced data (here http request)
   * Client side cache must be very short lived because the last visited products could have always been changed
   */
  getLastVisitedProductsData(): Observable<KurzLastVisitedProductsData> {
    return this.kurzLastVisitedProductsDataSync.getResponse();
  }


  private requestLastVisitedProductsData(): Observable<KurzLastVisitedProductsData> {

    const endpoint = '/my-details/last-visited-products';
    const url = getUrlOfEndpoint(endpoint);

    return this.httpClient.get<KurzLastVisitedProductsData>(url).pipe(
      catchError(err => {
        return of(<KurzLastVisitedProductsData>{products: [], url: ''});
      }),
      tap(data => {
        data.products = data.products?.map(product => (this.converterService.convert(product, PRODUCT_NORMALIZER) as KurzProduct)) || [];
      }),
      tap(data => {
        if (!data.url) {
          data.url = '/Baseprodukt---Klassifizierungen/c/baseProductClassification?query=:name-asc:allCategories:baseProductClassification';
          console.warn(`Url to all products was not found in response of "${endpoint}" and a hardcoded url was added`);
        }
      })
    );

  }

  getDeliveryAddresses(): Observable<KurzAddressList> {
    const cacheTimeout = 1500;
    return this.getCachableLanguageSusceptibleObservable(cacheTimeout, () => this.kurzCartService.getDeliveryAddresses());
  }

  getDeliveryCondition(): Observable<KurzConditionList> {
    const url = getUrlOfEndpoint('/org-units/shipping-conditions');
    const cacheTimeout = 1500;
    return this.getCachableLanguageSusceptibleObservable(cacheTimeout, () => this.kurzCartService.getShippingConditions());
  }

  getPaymentAddresses(): Observable<KurzAddressList> {
    const url = getUrlOfEndpoint('/org-units/billing-addresses');
    const cacheTimeout = 1500;
    return this.getCachableLanguageSusceptibleObservable(cacheTimeout, () => this.kurzCartService.getPaymentAddresses());
  }

  getPaymentCondition(): Observable<KurzConditionList> {
    const url = getUrlOfEndpoint('/org-units/billing-conditions');
    const cacheTimeout = 1500;
    return this.getCachableLanguageSusceptibleObservable(cacheTimeout, () => this.kurzCartService.getPaymentConditions());
  }


  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  getCustomersPage(page: number = 0, pageSize: number = 10, sort: string = 'byName'): Observable<{ users: User[]; }> {
    const url = getUrlOfEndpoint(`/users/current/orgCustomers?currentPage=${page}&pageSize=${pageSize}&sort=${sort}`);
    return this.httpClient.get<{ users: User[]; }>(url);
  }

  openAcceptanceOfLegalDocumentModal(documentType: 'termsOfUse' | 'privacyPolicy' | 'termsAndConditions'): Observable<boolean> {

    const whenDoneAsking = new Subject<boolean>();

    const mLazy = from(import('../kurz-components/shared/kurz-accept-legal-document-modal/kurz-accept-legal-document-modal.module')).pipe(take(1));
    const cLazy = from(import('../kurz-components/shared/kurz-accept-legal-document-modal/kurz-accept-legal-document-modal.component')).pipe(take(1));

    combineLatest([mLazy, cLazy]).pipe(
      filter(([m, c]) => {
        return !!m && !!c;
      }),
      take(1)
    ).subscribe(([m, c]) => {

      const data: KurzAcceptLegalDocumentModalData = {
        documentType
      };

      this.utilModalService.openCustomModal(c.KurzAcceptLegalDocumentModalComponent, data, {size: 'xl'})
        .waitForResult().pipe(take(1)).subscribe(
        res => {
          if (res) {
            this.sendAcceptTermsAncConditionsRequest().subscribe(() => {
              whenDoneAsking.next(true);
              whenDoneAsking.complete();
            });
          } else {
            this.authService.logout();
            whenDoneAsking.next(true);
            whenDoneAsking.complete();
          }
        }
      );
    });

    return whenDoneAsking;
  }

  checkAcceptanceOfTermsOfUse() {
    const url = getUrlOfEndpoint('/my-details/terms-and-conditions');
    const notAcceptedValue = 'NOT_ACCEPTED';

    this.httpClient.get<{ response: string; }>(url).subscribe(res => {
      if (res?.response === notAcceptedValue) {
        this.openAcceptanceOfLegalDocumentModal('termsOfUse').subscribe();
      }
    });
  }

  private sendAcceptTermsAncConditionsRequest(): Observable<{ value: string; }> {
    const url = getUrlOfEndpoint('/my-details/accept-terms-and-conditions');
    return this.httpClient.put<{ value: string; }>(url, {}).pipe(
      catchError((err, caught) => {
        console.warn('Accepting Terms And Conditions Request - failed', err, caught);
        const errorResponse = {value: 'error'};
        return of(errorResponse);
      })
    );
  }

  getRedirectList() {
    return this.kurzRedirectListAsyncDataCache.getResponse();
  }

  getCountries() {
    return this.kurzCountriesAsyncDataCache.getResponse();
  }

  getAllHybrisLanguages() {
    return this.kurzAllLanguagesAsyncDataCache.getResponse();
  }

  private requestRedirectListWithPreselectedUserLocation() {

    const url = getUrlOfEndpoint('/country-selection-list');

    return this.httpClient.get<KurzPreSelectedRedirectListElement[]>(url)
    .pipe(
      catchError(() => {
        return of(
          [] as KurzPreSelectedRedirectListElement[]
        );
      }),
      map(list => {

        let askRedirect = false;
        // breaks down more specific locale to their language
        const browserLang = new Intl.Locale(navigator.language).language;
        let userLang = Object.keys(languageFallbackMap).includes(browserLang) ? browserLang : 'en';
        let userLocation: (typeof list)[0];

        list.forEach(el => {
          // makes sure that url has a leading '/'
          if (!el.url.startsWith('/')) {
            el.url = '/' + el.url;
          }
          // makes sure that url has A trailing '/'
          if (!el.url.endsWith('/')) {
            el.url = el.url + '/';
          }
          // saves preselected element as userLocation
          if (el.preselected) {
            userLocation = el;
          }
        });

        if (userLocation) {
          const [redirectBaseSite] = userLocation.url.split('/').filter(p => !!p);
          askRedirect = redirectBaseSite !== environment.currentBaseSite;
        }

        return {
          askRedirect,
          list,
          userLang
        };
      })
    );
  }


  /**
   * Retrieves the user account manager as an Observable.
   * @returns {Observable<KurzAccountManager | null>} An Observable that emits the user account manager if available, otherwise null.
   */
  getUserAccountManager(): Observable<KurzAccountManager> {
    const url = getUrlOfEndpoint('/account-manager');
    return this.httpClient.get<KurzAccountManager>(url);
  }

  getBaseStoreCompanyProperties(): Observable<KurzBaseStoreCompanyProperties | null> {
    const url = getUrlOfEndpoint('/base-store/contact-data');
    return this.httpClient.get<KurzBaseStoreCompanyProperties | null>(url)
      .pipe(
        catchError(error => {
          console.error(error.error);
          return of(null);
        }),
      );
  }

}


