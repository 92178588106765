import { NgModule } from '@angular/core';
import { I18nConfig, provideDefaultConfig } from '@spartacus/core';


const i18nConfig: I18nConfig = {
  i18n: {
    resources: {
      en: {
        dropdown: {
          dropdown: {
            noItems: 'There are no items',
            all: 'All',
            none: 'None',
            searchFieldPlaceholder: 'Text filter',
            apply: 'Apply',
            required_multi: 'At least one valid item is required',
            required_single: 'Select a valid item'
          }
        },
        autocomplete: {
          autocomplete: {
            required: 'Select a valid item'
          }
        }
      },
      de: {
        dropdown: {
          dropdown: {
            noItems: 'Es gibt keine Einträge',
            all: 'Alle',
            none: 'Keines',
            searchFieldPlaceholder: 'Textfilter',
            apply: 'Anwenden',
            required_multi: 'Wählen Sie mindestens eine valide Option aus',
            required_single: 'Wählen Sie eine valide Option aus'
          }
        },
        autocomplete: {
          autocomplete: {
            required: 'Bitte wählen Sie eine valide Option aus'
          }
        }
      },
      fr: {
        dropdown: {
          dropdown: {
            noItems: 'Il n\'y a pas d\'entrées',
            all: 'Toute',
            none: 'Acoune',
            searchFieldPlaceholder: 'Filtre de texte',
            apply: 'Reprendre',
            required_multi: 'Sélectionnez au moins une option valide',
            required_single: 'Sélectionnez une option valide'
          }
        },
        autocomplete: {
          autocomplete: {
            required: 'Sélectionnez une option valide'
          }
        }
      },
      nl: {
        dropdown: {
          dropdown: {
            noItems: 'Er zijn geen vermeldingen',
            all: 'Alle',
            none: 'Geen',
            searchFieldPlaceholder: 'Tekstfilter',
            apply: 'Overnemen',
            required_multi: 'Selecteer ten minste één geldige optie',
            required_single: 'Selecteer één geldige optie'
          }
        },
        autocomplete: {
          autocomplete: {
            required: 'Selecteer één geldige optie'
          }
        }
      },
    },
    chunks: {
      dropdown: ['dropdown'],
      autocomplete: ['autocomplete']
    },
    fallbackLang: 'en'
  }
};


@NgModule({
  providers: [
    provideDefaultConfig(i18nConfig)
  ]
})
export class DropdownTranslationsModule { }

