import { KurzTabBarStructure } from '@util/components/tab-bar/tab-bar.types';




export const kurzMyDetailsTabsMockJSON: KurzTabBarStructure = {
  'mainTabs': [
    {
      'queryParamIdentifier': 'my-data',
      'titleCode': 'myDetails.myData.mainTitle',
      'hideSingleSubTab': true,
      'subTabs': [
        {
          'queryParamIdentifier': 'my-profile',
          'titleCode': 'myDetails.myData.myProfileTitle',
          'contentComponentUid': 'KurzMyProfileComponentUID'
        },
        // {
        //   'queryParamIdentifier': 'change-password',
        //   'titleCode': 'myDetails.myData.changePasswordTitle',
        //   'contentComponentUid': 'UpdatePasswordComponent'
        // }
      ]
    },
    {
      'queryParamIdentifier': 'delivery',
      'titleCode': 'myDetails.delivery.mainTitle',
      'subTabs': [
        {
          'queryParamIdentifier': 'delivery-address',
          'titleCode': 'myDetails.delivery.deliveryAddressTitle',
          'contentComponentUid': 'KurzDeliveryAddressComponentUID'
        },
        {
          'queryParamIdentifier': 'delivery-condition',
          'titleCode': 'myDetails.delivery.deliveryConditionTitle',
          'contentComponentUid': 'KurzDeliveryConditionComponentUID'
        }
      ]
    },
    {
      'queryParamIdentifier': 'payment',
      'titleCode': 'myDetails.payment.mainTitle',
      'subTabs': [
        {
          'queryParamIdentifier': 'payment-address',
          'titleCode': 'myDetails.payment.paymentAddressTitle',
          'contentComponentUid': 'KurzPaymentAddressComponentUID'
        },
        {
          'queryParamIdentifier': 'payment-condition',
          'titleCode': 'myDetails.payment.paymentConditionTitle',
          'contentComponentUid': 'KurzPaymentConditionComponentUID'
        }
      ]
    },
    {
      'queryParamIdentifier': 'administration',
      'titleCode': 'myDetails.administration.mainTitle',
      'subTabs': [
        {
          'queryParamIdentifier': 'manage-users',
          'titleCode': 'myDetails.administration.manageUsersTitle',
          'contentComponentUid': 'KurzManageUsersComponent'
        }
      ]
    }
  ]
};
