import { inject, Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpEventType, HttpResponse } from '@angular/common/http';
import { catchError, Observable, of, ReplaySubject, Subject, take, tap } from 'rxjs';
import { OccEndpointsService } from '@spartacus/core';
import { deepCopy } from '@util/functions/objects';


export interface OccApiCacheMapEntry {
  /**
   * body of the http response
   */
  responseSubject?: Subject<HttpResponse<any>>;
  /**
   * timestamp when response is too old
   */
  maxAgeTimestamp: number;
  /**
   * rules on when and how long to save the cache
   */
  options: OccApiCacheOptions;
}

export interface OccApiCacheOptions {
  /**
   * whether all query params in the current url is ignored in building the key and refering to the correct
   * cache.
   * if this is true, "ignoreSearchParamList" won't have an effect
   */
  ignoreAllSearchParams?: boolean;
  /**
   * an array of search params, which will be removed from the key for the caching
   */
  ignoreSearchParamList?: string[];
  validFor: number;
}


@Injectable()
export class OccApiCacheInterceptor implements HttpInterceptor {

  private occEndpoints = inject(OccEndpointsService);

  /**
   * Map for determine, which request are even considered of caching with the help of url parts
   * and its options on how to build a related url that can be used as a key in the map for the cache
   * Key: url parts (string part of the endpoint to uniquely differentiate an occ api endpoint)
   */
  private static urlPartsAndOptionsMap = new Map<string, OccApiCacheOptions>();

  /**
   * Map for the cache
   * Key: related url (basically only the endpoint with context related search query params)
   */
  private responseRelatedUrlCacheEntryMap = new Map<string, OccApiCacheMapEntry>();


  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // create url object from url of current request
    const url = new URL(request.urlWithParams);

    // get current occ base url
    const baseUrl = this.occEndpoints.getBaseUrl();

    // test if current request is a occ api endpoint
    const isOccApiRequest = url.href.includes(baseUrl);

    let foundCacheMapEntry: OccApiCacheMapEntry;
    let cacheValid = false;
    /**
     * a.k.a. key of the cache entry, may contain important context according to OccApiCacheOptions
     */
    let currentResponseRelatedUrl: string;
    let newlyCreated = false;

    // getting all entries of url parts and their respective options on how to get the key (related url) for the cache map
    const urlPartOptionsEntries = isOccApiRequest ? Array.from(OccApiCacheInterceptor.urlPartsAndOptionsMap.entries()) : [];

    // searching for the options -> loop through all url parts and find the opütions, whose url part fits the current url (only pathname + search query to avoid false positives as much as possible)
    const foundOptions = urlPartOptionsEntries.find(([key,]) => {
      return (url.pathname + url.search).includes(key);
    })?.[1];

    // if a related url was registered and its options were found
    if (foundOptions) {
      // build its key for searching for its cache entry
      currentResponseRelatedUrl = this.transformUrlToComparableResponseRelatedUrl(url, foundOptions);
      // get its cache entry
      foundCacheMapEntry = this.responseRelatedUrlCacheEntryMap.get(currentResponseRelatedUrl);

      // if cache entry does not exist yet
      if (!foundCacheMapEntry) {
        // remember that it was newly created
        newlyCreated = true;
        // create it with a new replay subject
        foundCacheMapEntry = {
          maxAgeTimestamp: 0, // making sure that the entry will fail validation as too old
          options: foundOptions,
          responseSubject: new ReplaySubject()
        };
        // save it in the cache
        this.responseRelatedUrlCacheEntryMap.set(currentResponseRelatedUrl, foundCacheMapEntry);
      }
    }

    // if options were found the cache entry exists as well
    if (foundCacheMapEntry) {

      // was cache entry NOT newly created and it is NOT too old -> it is valid
      cacheValid = !newlyCreated && Date.now() <= foundCacheMapEntry.maxAgeTimestamp;

      // and only if it is valid its replay subject is returned (which will be filled with data eventually)
      if (cacheValid) {
        return foundCacheMapEntry.responseSubject.asObservable();
      } else {
        // recalculating maxAgeTimestamp so that next requests (those between now and the response of this request) will receive the foundCacheMapEntry.responseSubject
        foundCacheMapEntry.maxAgeTimestamp = Date.now() + foundCacheMapEntry.options.validFor;
      }
    }

    return next.handle(request).pipe(
      tap((event: HttpEvent<any>) => {

        // only if it was a registered occ api endpoint detected by an url part (= foundCacheMapEntry defined) and the cache of the entry is not valid
        if (event?.type === HttpEventType.Response && foundCacheMapEntry && !cacheValid) {

          // correct its maxAge
          foundCacheMapEntry.maxAgeTimestamp = Date.now() + foundCacheMapEntry.options.validFor;

          let statusClass = (event.status + '')[0];

          // if it is of status 2xx -> save response in the replay subject
          if (statusClass === '2') {
            const cacheResponse = new HttpResponse({status: 200, body: deepCopy(event.body)});
            foundCacheMapEntry.responseSubject.next(cacheResponse);
          }

          // if it is of status 4xx to 5xx -> save error in replay subject
          // complete and delete entry so that if another request asks for it again
          // it will try anew
          if (statusClass === '4' || statusClass === '5') {
            foundCacheMapEntry.responseSubject.error(event);
            foundCacheMapEntry.responseSubject.complete();
            this.responseRelatedUrlCacheEntryMap.delete(currentResponseRelatedUrl);
          }
        }

      })
    );
  }

  private transformUrlToComparableResponseRelatedUrl(url: URL, opt: OccApiCacheOptions) {
    const path = url.pathname;
    let sortedFilteredParams: string[] = [];
    if (!opt.ignoreAllSearchParams) {
      sortedFilteredParams = Array.from(url.searchParams.entries())
      .filter(p => !(opt.ignoreSearchParamList.includes(p[0])))
      .sort((a, b) => {
        return a[0].localeCompare(b[0]);
      })
      .map(p => p[0] + '=' + p[1])
      ;
    }
    return path + ((sortedFilteredParams.length > 0) ? ('?' + sortedFilteredParams.join('&')) : '');
  }

  /**
   * register occ api endpoints by uniquely identifieable url parts and give them options to determine context specifcy
   * @example
   * registerUrlPartForCaching('/get-profession', {validFor: 1000 * 60, ignoreSearchParamList: ['curr']});
   * // it registered a cache for "/get-profession" and a different search parameter for "curr", would not create an extra cache but
   * // if the endpoint is called once with ?lang=de and once with ?lang=en, it would create two different caches
   */
  static registerUrlPartForCaching(urlPart: string, options?: OccApiCacheOptions) {
    this.urlPartsAndOptionsMap.set(urlPart, options);
  }
}
