import { NgModule } from '@angular/core';
import { provideConfig, provideConfigFactory } from '@spartacus/core';
import { translationsMergerFactory } from './custom-translations';
import { DropdownTranslationsModule } from '@util/components/dropdown/dropdown-translations.module';
import { BaseFormValidatorsTranslationsModule } from '@util/components/shared/base-form/base-form-validators/base-form-validators-translations.module';

interface LanguageContextConfig {
  context: {
    language: string[];
  };
}

const contextWantedLanguagesConfig: LanguageContextConfig = {
  context: {
    language: ['de', 'en', 'fr', 'en_US', 'en_GB', 'fr_FR', 'fr_CH', 'fr_LU', 'nl']
  }
};


export const languageFallbackMap = {
  'de': ['en'],
  'en_GB': ['en'],
  'en_US': ['en'],
  'nl': ['en'],
  'fr': ['en'],
  'fr_FR': ['fr', 'en'],
  'fr_CH': ['fr', 'en'],
  'fr_LU': ['fr', 'en', 'de']
};


@NgModule({
  declarations: [],
  imports: [
    DropdownTranslationsModule,
    BaseFormValidatorsTranslationsModule,
  ],
  providers: [
    provideConfigFactory(translationsMergerFactory),
    provideConfig(contextWantedLanguagesConfig)
  ],
  exports: []
})
export class CustomTranslationsModule {
}
